<template>
  <div>

  </div>

</template>
<script>
import {getcurrentUser} from '@/api'
import store from '../../store'
export default{
  data(){
    return{

    }
  },
  created () {
    
    this.getToken()

},
mounted () {

},
methods: {
 async getToken(){
const res =await getcurrentUser()
if (res.code==0&&res.message==='success') {
  if (res.data.roles[0].id==1) {
    localStorage.setItem('areaCode','330000')    
    this.$router.push('/city')
  }else if(res.data.roles[0].id==2&&res.data.areaType==3){
    this.$router.push('/newDistrict')
  }else{
    localStorage.setItem('areaCode',res.data.area)    
    this.$router.push('/city')
  }
  // const token =this.$route.query.token
  const token="BearereyJhbGciOiJIUzUxMiJ9.eyJleHAiOjE3Mjg2MjQ4MTgsInVzZXIiOnsiaWQiOjI2NTIsIm5hbWUiOiLlvpDkvankvakiLCJ1c2VybmFtZSI6InhwcCIsInBhc3N3b3JkIjoiJDJhJDEwJG55RHBwMzdDVEdaaWVQVFBOeVlVcE9hRzEzRjB0RHc3RDdhWktnSzZpQk1OdG1aeTQ0RUU2IiwicmVhbFBhc3N3b3JkIjpudWxsLCJwaG9uZSI6IjEzMTI5OTIyNDg3IiwibWFpbCI6bnVsbCwic2l0ZUlkIjpudWxsLCJkZXBhcnRtZW50SWQiOm51bGwsImdtdGNyZWF0ZSI6MTcxMDE0NDM0MzAwMCwibm90aWZpZXIiOmZhbHNlLCJzdGF0dXMiOnRydWUsInJlbWFyayI6bnVsbCwidXNlcmZhY2UiOm51bGwsInRlbGVwaG9uZSI6bnVsbCwibGFzdExvZ2luIjoxNzI4NDU4NTc3MDAwLCJ0eXBlIjowLCJ3YXkiOm51bGwsIm9wZW5pZCI6Im9nblhkNVJfdjdJeUpLeVdIWVBvbnllMUhlUUkiLCJwdXNoT3BlbmlkIjpudWxsLCJpc2NOb3RpZmllciI6bnVsbCwid3hOb3RpZmllciI6bnVsbCwic3lzdGVtTm90aWZpZXIiOm51bGwsImFyZWFUeXBlIjpudWxsLCJkaXNoQ29tcGFueUlkIjpudWxsLCJzaXRlTmFtZSI6bnVsbCwicmVtZW1iZXIiOmZhbHNlLCJjb2RlIjpudWxsLCJyb2xlcyI6W3siaWQiOjEsIm5hbWUiOiJST0xFX1NVUEVSIiwibmFtZXpoIjoi57O757uf566h55CG5ZGYIn1dLCJhcmVhcyI6bnVsbCwiYXJlYSI6bnVsbCwiZW5hYmxlZCI6dHJ1ZSwiY3JlZGVudGlhbHNOb25FeHBpcmVkIjp0cnVlLCJhdXRob3JpdGllcyI6W3siYXV0aG9yaXR5IjoiUk9MRV9TVVBFUiJ9XSwiYWNjb3VudE5vbkxvY2tlZCI6dHJ1ZSwiYWNjb3VudE5vbkV4cGlyZWQiOnRydWV9LCJzdWIiOiJ4cHAifQ.Ab0s_y-daLWgBO4JbcYlA-bzIjCxKogBJP7QtKDZknugTlQoj5FWifWylUqTtlnOm0-X3yO0j36eM4V9Kjs_lw"
    localStorage.setItem('token',token)
    store.dispatch('GET_TOKEN')  
}
}
}
}
</script>